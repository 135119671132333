.playerImg {
    height: 30px; /* Set the image height to 30px */
    width: auto; /* Adjust width automatically to maintain aspect ratio */
    /* Or specify a fixed width if you prefer */
    /* width: 20px; */
}

.name {
    font-size: 2rem;
}

.karte {
    display: block;
    width: 12px;
    height: 17px;
    border-radius: 2px;

    &_wrapper {
        transform: scaleX(-1);
        display: flex;
    }

    &.red {
        background: #E0162E;
        border: 2px solid #910C0C;
    }

    &.yellow {
        background: #F9C700;
        border: 2px solid #A37F00;
        position: relative;

        &.ltr:not(:first-child) {
            margin-left: -6px;
        }

        &.rtl:not(:first-child) {
            margin-right: -6px;
        }
    }
}