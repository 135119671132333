/* typography and text related utility classes */

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: var(--heading-font);
    font-weight: 700;
    color: var(--header);

    &.light {
        color: var(--header-light);
    }

    &.dark {
        color: var(--header-dark);
    }
}

h1, .h1 {
    font-size: 1.75rem;
    line-height: 1.4rem;

    &.large {
        font-size: 3rem;
        height: 3rem;
        display: inline-flex;
        align-items: center;
    }
}

h2, .h2 {
    font-size: 1.375rem;
}

h3, .h3 {
    font-size: 1rem;
    line-height: 1.25rem;
    margin-bottom: 20px;
}

h4, .h4 {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25rem;
}

h5, .h5 {
    font-size: 0.75rem;
    font-weight: 600;
}

h6, .h6 {
    font-size: 0.5625rem;
    font-weight: 600;
}

.heading-font {
    font-family: var(--heading-font);
}

.text-16 {
    font-size: 1rem;
}

.text-20 {
    font-size: 1.25rem;
    line-height: 1.2;
}

.text-center {
    text-align: center;
}

.lh-1 {
    line-height: 1;
}