.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 4px;
    margin-bottom: 20px;
    background-color: #171A1A;


    &.light {
        background: var(--widget);
        box-shadow: 0 1px 8px rgba(110, 110, 110, .1);
    }

    &.dark {
        background: var(--border);
    }
}

.field {
    max-width: 220px !important;
    height: 264px !important;
    margin-top: 30px !important;

    // tablet portrait
    @media screen and (min-width: 768px) {
        margin-top: 10px;
    }
}

.day {
    color: orange;
}

.active {

}

h5, .h5 {
    font-size: 0.75rem;
    font-weight: 600;
    max-width: 80px;
}