/* styles.module.scss */
.card {
    padding: var(--card-padding);
    background-color: var(--widget-dark);
}

.grid {
    display: grid;
    padding: 30px;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.grid2 {
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 30px;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.cardColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.middleCol {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.clubLogo {
    width: 80px;
    height: auto;
    background-color: white;
    padding: 5px;
}

.logo {
    object-fit: contain;
}
.clubDress {
    width: auto;
    height: 80px;
}

.teamName {
    max-width: 100px;
    margin-top: 10px;
    height: 35px;
    text-align: center;
    margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
    .grid {
        grid-template-columns: repeat(3, 1fr);
    }

    .grid2 {
        grid-template-columns: repeat(2, 1fr);
    }

    .card {
        height: auto;
        padding: 20px;
    }
}

//

.row {
    display: flex;
    justify-items: center;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.actions {
    display: flex;
    justify-items: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
}


// views.module.scss
.viewContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    border: 1px solid #ccc;
    margin-top: 20px;
    //background-color: #f9f9f9;
    border-radius: 8px;
}

h3 {
    font-size: 1.5rem;
    color: #333;
}

h4 {
    font-size: 1rem;
    color: white;
}

// styles.module.scss

.card {
    padding: 20px;
    background-color: var(--widget-dark);
    border-radius: 8px;
    margin-top: 20px;
}

.matchDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 20px;
    background-color: var(--background-light);
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.teamInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.teamLogo {
    width: 100px;
    height: auto;
    margin-top: 10px;
}

.scoreInfo {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
}

.btn {
    padding: 10px 30px;
    border-radius: 5px;
    margin: 0px 10px;
}

.btnGreen {
    padding: 10px 30px;
    background-color: green;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    margin: 0px 10px;
}



.btnOrange {
    padding: 10px 30px;
    background-color: orange;
    font-weight: bold;
    color: black;
    border-radius: 5px;
    margin: 0px 10px;
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover and state changes */

    &:hover {
        background-color: orangered;
        color: white; /* Change text color for selected state */

    }

    &.selected {
        background-color: red;
        color: white; /* Change text color for selected state */
    }

    &:disabled {
        background-color: #ccc; /* Gray background for disabled state */
        color: #666; /* Gray text color for disabled state */
        cursor: not-allowed; /* Optional: Change cursor for disabled state */
    }
}

.btnOrange:disabled {
    background-color: #ccc; /* Gray background for disabled state */
    color: #666; /* Gray text color for disabled state */
    cursor: not-allowed; /* Optional: Change cursor for disabled state */
}

.btnGreen:disabled {
    background-color: #ccc; /* Gray background for disabled state */
    color: #666; /* Gray text color for disabled state */
    cursor: not-allowed; /* Optional: Change cursor for disabled state */
}


.stopwatchContainer {
    text-align: center;
    padding: 1rem; // Optional: Adjust padding as needed
}

.counter {
    font-size: 2rem; // Example styling, adjust as needed
    font-weight: bold;
    color: white;
    line-height: 1.5;

}

.counterLbl {
    font-size: .75rem; // Example styling, adjust as needed
    margin-bottom: 15px;
    font-weight: normal;
    color: white;
}
/* Add this CSS for basic modal styling */
.modalContent {
    width: 90%;
    max-width: 500px; /* Increased width for better layout */
    max-height: 80vh;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: black;
    padding: 30px;
    outline: none;
    border-radius: 8px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* Remove align-items: center to allow stretching */
    /* align-items: center; */
    overflow: hidden; /* Prevent outer scrolling */
}

.location {
    color: orange;
    text-align: center;
    margin-top: 30px;
    font-weight: bold;
    padding: 10px 0px;
}

.playerModalContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: white;
    margin: 30px;
    background-color: #000000;
    padding: 20px; /* Adds some inner spacing */
    border-radius: 10px; /* Rounds the corners for a smooth look */
    box-shadow: 0 4px 10px rgba(255, 150, 150, 0.1), 0 6px 20px rgba(0, 0, 0, 0.5); /* Adds a multi-layered shadow */
}
.playerModalContainerName {
    color: white;
}

.playerModalContainerNumber {
    color: orange;

}

.title {
    color: white;
    font-size: 16px;
    font-weight: bold;
}

//.modalContent {
//    position: absolute;
//    top: 50%;
//    left: 50%;
//    transform: translate(-50%, -50%);
//    background-color: white;
//    padding: 20px;
//    outline: none;
//    border-radius: 8px;
//    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
//    max-width: 90%;
//    max-height: 90%;
//    overflow-y: auto;
//}

.playerList {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    flex: 1; /* Allow the list to take up available space */
    overflow-y: auto; /* Enable vertical scrolling */
    padding-right: 10px; /* Optional: Add padding to prevent scrollbar overlap */
}
.playerList::-webkit-scrollbar {
    width: 8px;
}

.playerList::-webkit-scrollbar-thumb {
    background-color: rgba(255, 165, 0, 0.5); /* Orange scrollbar thumb */
    border-radius: 4px;
}

.playerList::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.1); /* Light track */
}

.playerRow {
    display: flex;
    align-items: center;
    padding: 10px 30px;
    margin: 5px 0;
    cursor: pointer;
    gap: 8px;
    //border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #2e2e2e;



    &:hover {
        background-color: orange;
        color: black;
    }
}

.selected {
    background-color: #cce5ff;
    border-color: #007bff;
}

.actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.grid3 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    margin-top: 20px;
}

.gridItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 120px; /* Increased the width to make it more balanced */
    height: 120px; /* Increased the height to make more room */
    cursor: pointer;
}

.gridItem img {
    width: 30px;
    height: 30px;
    object-fit: contain;
    margin-bottom: 5px; /* Added space between the image and text */
}

.gridItem p {
    margin-top: 5px;
    text-align: center;
    font-size: 14px;
    color: #ccc; /* Adjust the text color if necessary */
}

.karte {
    display: block;
    width: 12px;
    height: 17px;
    border-radius: 2px;

    &_wrapper {
        transform: scaleX(-1);
        display: flex;
    }

    &.red {
        background: #E0162E;
        border: 2px solid #910C0C;
    }

    &.yellow {
        background: #F9C700;
        border: 2px solid #A37F00;
        position: relative;

        &.ltr:not(:first-child) {
            margin-left: -6px;
        }

        &.rtl:not(:first-child) {
            margin-right: -6px;
        }
    }
}

.eventCard {

}

.eventList {
    display: flex;
    flex-direction: column;
    gap: 10px;

}

.eventDetails {
    display: flex;
    align-items: center;
    gap: 5px;
}

.eventDetailsWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1; /* Allow the event details to take up most space */
}

.eventImage {
    width: 20px;
}

.header {
    color: white;
}

.eventRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #333; /* Adjust background as needed */
    border-radius: 8px;
}
.deleteButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color 0.3s;

    &:hover {
        color: red;
    }
}

.date {
    color: white;
    font-size: 16px;
    font-weight: bold;
}

.league {
    color: white;
    font-size: 14px;
    font-weight: lighter;
    margin-bottom: 10px;
}

.destroy {
    padding: 10px 30px;
    background-color: indianred;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    margin: 0px 10px;
}

